
//=============================================================================
// Función Genérica Renee_Main
//=============================================================================

export default async function APICALL(data) {

	const ID = sessionStorage.getItem('environmentMain');
	const ID2 = sessionStorage.getItem('test');
	let isTestEnvironment
	let URL 

	if (ID === 'Local') {
		URL = 'http://localhost:8070/MainCall'
	} else {
		URL = 'https://api.renee.cl/MainCall'
	}

	if ( ID2 === 'Test' ) {
		isTestEnvironment = window.location.hostname !== 'app.renee.cl';
	} else {
		isTestEnvironment = false;
	}

	return fetch( URL , {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(	{	data,
														environment: isTestEnvironment ? 'test' : 'production'}),
		mode: 'cors'
	})
	.then(response => response.json())
	.catch(error => {
			console.error('Error during fetch:', error);
			return { response: '' };
	});

}
