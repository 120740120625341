
import './functions/css/index.css';
import './functions/css/App.css';

import React from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

import Header from './components/header';
import HeaderAdmin from './components/headerAdmin.js';
import App from './App';

import Ideas from './functions/00_Ideas.js';
import Puser from './functions/00_NuevoPass.js';
import Nuser from './functions/00_NuevoUsuario.js';
import NEuser from './functions/00_NuevoUsuarioEmp.js';

import ErrorBoundary from './functions/XX_Errorboundry.js';

import Pprocess   from './functions/01_printprocess.js';
import Nopciones  from './functions/02_newoptions.js';
import Editor     from './functions/03_Text_editor.js';
import Eprocess   from './functions/04_edtprocess.js';
import Perfil     from './functions/05_GenPerfil.js';
import Public     from './functions/06_Public.js';
import Recuperar  from './functions/07_Recover.js';
import Formulario from './functions/08_Formulary.js';
import Respuestas from './functions/09_Respuestas.js';

import MS         from './functions/00_MS.js';

const root = ReactDOM.createRoot(document.getElementById('root'));

if (!sessionStorage.getItem('token')){
  root.render(
    <React.StrictMode>
      <ErrorBoundary>
        <Router>
          <Routes>
              <Route path='/'                   element={<App/>} />  
              <Route path='/MS'                 element={<MS/>} /> 
          </Routes>
        </Router>
      </ErrorBoundary>
    </React.StrictMode>
  )
}
else{
  root.render(
    <React.StrictMode>
      <ErrorBoundary>
        <Router>
        {sessionStorage.getItem('rol') !== 'User' && (
          <HeaderAdmin/>
        )}
        {sessionStorage.getItem('rol') === 'User' && (
          <Header/>
        )}
          <Routes>

            <Route path='/MS'                 element={<MS/>} /> 
            <Route path='/Ideas'              element={<Ideas/>} /> 
            <Route path='/MiPerfil'           element={<Puser/>} /> 
            
            <Route path='/'                   element={<Pprocess/>} />  
            <Route path='/CrearProceso'           element={<Nopciones/>} />
            <Route path='/P_Notas'              element={<Editor/>} />
            <Route path='/P_Editar'           element={<Eprocess/>} />
            <Route path='/P_Perfil'          element={<Perfil/>} />
            <Route path='/P_Oferta'          element={<Public/>} />
            <Route path='/P_Candidatos'         element={<Recuperar/>} />
            <Route path='/P_Formulario'         element={<Formulario/>} />
            <Route path='/P_Respuestas'         element={<Respuestas/>} />
            
            {sessionStorage.getItem('rol') === 'Admin' && (
            <Route path='/NuevoUser' element={<Nuser />} />
            )}
            {sessionStorage.getItem('rol') !== 'User' && (
              <Route path='/NuevoUser' element={<NEuser />} />
            )}
            
          </Routes>
        </Router>
      </ErrorBoundary>
    </React.StrictMode>
  );
}

reportWebVitals();
