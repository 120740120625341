
//=============================================================================
// Pantalla Generación Oferta/ Preguntas | Publicación
//=============================================================================

import './css/App.css';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// import { jsPDF } from 'jspdf';
import APICALL from './APICALL';
import AICALL from './AICALL';

export default function Public() {

  const navigate = useNavigate();
  const [Oferta, setOferta] = useState('');
  const [Final1, setFinal1] = useState('');
  const [Final2, setFinal2] = useState('');
  const [Final3, setFinal3] = useState('');
  const [Final4, setFinal4] = useState('');
  const [Pregunta1, setPregunta1] = useState('');
  const [Pregunta2, setPregunta2] = useState('');
  const [Pregunta3, setPregunta3] = useState('');
  const [Pregunta4, setPregunta4] = useState('');
  const [FetchedData, setFetchedData] = useState(''); 
  const [IDlist,setIDlist] = useState([]);
  const [Claves, setClaves] = useState(['']);
  const [Wait, setWait] = useState(false);
  const [Wait2, setWait2] = useState(false);
  const [Regen, setRegen] = useState(false);
  const [Actual, setActual] = useState(false);
  const [Publicar, setPublicar] = useState(false);
  const handleChange = (event) => { setOferta(event.target.value) };

  const Notas1 = 'Genera En el titulo utiliza el formato : '
                  + 'Buscamos {nombre del cargo} para compañía de {industria donde se busca al candidato}.'
                  + 'Genera El aviso como un proceso de búsqueda para la empresa' 
                  + sessionStorage.getItem('grupo')
                  + 'En el texto generado refiere a la empresa como "nuestro cliente" en lugar de su nombre';

  const Notas2 = 'Genera El aviso como un proceso de busqueda para una empresa externa' 
                  + 'Mantieniendop un lenguaje formal del estilo "la empresa"';

  const Notas3 = 'Genera El aviso como un proceso de busqueda para una empresa incognita' 
                  + 'evita cualquier mencion del nombre de la empresa';

  async function ExportToTrabajando() {
    await SaveData()
    setWait(true);
    setWait2(true);

    try {
      const reply = await APICALL({
        apicall: 'TB_create',
        code: sessionStorage.getItem('process'),
        user: sessionStorage.getItem('token'),
        mail: sessionStorage.getItem('mail'),
        U_emp: sessionStorage.getItem('Grupo')
      });
      SaveJobId(reply.id);
      window.open(reply.url);
    } catch (error) {
      console.error('Error during fetch:', error);
    }
    setTimeout(() => {
      setWait(false);
      setWait2(false);
    }, 250);
  };

  async function FetchData () {
    const data = await APICALL({
      apicall :'PD_fetch' ,
      code :sessionStorage.getItem('process'),
      U_emp: sessionStorage.getItem('Grupo')
    });
    localStorage.removeItem('fetched');
    localStorage.setItem('fetched', data);
    setOferta(data.GPToferta);
    setPregunta1(data.GPTpreguntas);
    if (!data.GPToferta){
      GenerateOferta();
    } 
    if (!data.GPTpreguntas){
      GeneratePreguntas();
    }
  };

  async function GenerateOferta(notas) {
    setWait(true);
    setOferta('Espera un momento mientras Reneé Trabaja ...')
    try {
      const newText = await AICALL({
        apicall: '02',
        data: localStorage.getItem('reply') 
              + localStorage.getItem('fetched')
              + notas
      });
      setOferta(FormatResponse(JSON.parse(newText.reply)));
    } catch (error) {
      console.error('Error during fetch:', error);
      return { token: null };
    }
    setTimeout(() => {
      setWait(false);
    }, 250);
  };

  async function GeneratePreguntas() {
    setWait2(true);
    if (!Final1){
      setPregunta1('Espera un momento mientras Reneé Trabaja ...')
    }
    if (!Final2){
      setPregunta2('Espera un momento mientras Reneé Trabaja ...')
    }
    if (!Final3){
      setPregunta3('Espera un momento mientras Reneé Trabaja ...')
    }
    if (!Final4){
      setPregunta4('Espera un momento mientras Reneé Trabaja ...')
    }
    
    try {
      const newText = await AICALL({
        apicall: '03',
        data: localStorage.getItem('reply') + localStorage.getItem('fetched')
      });
    
      const parsedReply = JSON.parse(newText.reply);
    
      if (!Final1) {
        setPregunta1(parsedReply.Pregunta_1);
      }
      if (!Final2) {
        setPregunta2(parsedReply.Pregunta_2);
      }
      if (!Final3) {
        setPregunta3(parsedReply.Pregunta_3);
      }
      if (!Final4) {
        setPregunta4(parsedReply.Pregunta_4);
      }
    } catch (error) {
      console.error('Error during fetch:', error);
      return { token: null };
    }
    
    setTimeout(() => {
      setWait2(false);
    }, 250);
  };

  async function SaveData() {
    setWait(true);
    setWait2(true);
    try {
      await APICALL({
        apicall: 'PD_update',
        code: sessionStorage.getItem('process'),
        U_emp: sessionStorage.getItem('Grupo'), 
        Claves: Claves,
        GPToferta: Oferta,
        GPTpreguntas: [Pregunta1.replace(/"/g, '') , Pregunta2.replace(/"/g, '') , Pregunta3.replace(/"/g, '') , Pregunta4.replace(/"/g, '')]
      });
      return true;
    } catch (error) {
      console.error('Error during fetch:', error);
      return { token: null };
    }  finally {
      setTimeout(() => {
        setWait(false);
        setWait2(false);
      }, 250); 
    }
  };

  async function SaveJobId(ID) {
    if (!IDlist.includes(ID)) {
      IDlist.push(ID);
    }
    try {
      await APICALL({
        apicall: 'PD_update',
        code: sessionStorage.getItem('process'),
        U_emp: sessionStorage.getItem('Grupo'),
        JobId: IDlist.filter(item => {return item !== null && item !== undefined && item !== ''})
      });
      return true;
    } catch (error) {
      console.error('Error during fetch:', error);
      return { token: null };
    }
  };

  async function UpdateTrabajando() {
    await SaveData()
    setWait(true);
    setWait2(true);

    try {
      const reply = await APICALL({
        apicall: 'TB_update',
        code: sessionStorage.getItem('process'),
        user: sessionStorage.getItem('token'),
        mail: sessionStorage.getItem('mail'),
        U_emp: sessionStorage.getItem('Grupo')
      });
      SaveJobId(reply.id);
      window.open(reply.url);
    } catch (error) {
      console.error('Error during fetch:', error);
    }
    setTimeout(() => {
      setWait(false);
      setWait2(false);
    }, 250);
  };

  // const exportToPDFoferta = () => {
  //   const doc = new jsPDF();

  //   doc.text(oferta, 10, 10);
  //   doc.save('oferta.pdf');
  // };

  const FormatResponse = (jobDetails) => {
    
    let formattedText = '';

    formattedText += jobDetails.Descripcion_de_la_empresa+ '\n\n';
  
    formattedText += `**Misión del Cargo:**\n${jobDetails.Mision_del_cargo}\n\n`;
  
    formattedText += `**Responsabilidades:**\n`;
    jobDetails.Responsabilidades.forEach((responsabilidad, index) => {
      formattedText += `~${responsabilidad}~\n`;
    });
    formattedText += `\n`;
  
    formattedText += `**Requisitos Clave:**\n`;
    jobDetails.Claves.forEach((clave, index) => {
      formattedText += `~${clave}~\n`;
    });
    formattedText += `\n`;
  
    formattedText += `**Oferta:**\n`;
    formattedText += `~Banda Salarial: ${jobDetails.Oferta.banda_salarial}~\n`;
    formattedText += `~Horario: ${jobDetails.Oferta.horario}~\n`;
    formattedText += `~Modalidad de Trabajo: ${jobDetails.Oferta.modalidad_de_trabajo}~\n`;
    formattedText += `~Beneficios Adicionales: ${jobDetails.Oferta.beneficios_adicionales}~\n`;
    formattedText += `\n`;

    formattedText += jobDetails.Captura + '\n';

    let formatedclave = ''
    jobDetails.Claves.forEach((clave, index) => {
      formatedclave += `~${clave}~\n`;
    });
    setClaves(formatedclave);
    return formattedText;
  }

  const goto05 = () => { navigate('/P_Perfil') };
  const goto07 = () => { navigate('/P_Candidatos') };

  useEffect(() => {
    const fetchData = async () => {
      const data = await APICALL({
        apicall :'PD_fetch' ,
        code :sessionStorage.getItem('process'),
        U_emp: sessionStorage.getItem('Grupo')
      });
      localStorage.removeItem('fetched');
      localStorage.setItem('fetched', data);
      setIDlist(data.JobId || []);
      setFetchedData(data);
      setClaves(data.Claves)
      if (data.GPToferta){
        setOferta(data.GPToferta);
      } else {
        GenerateOferta();
      }
      if (data.GPTpreguntas){
        
        setPregunta1(data.GPTpreguntas[0]);
        setPregunta2(data.GPTpreguntas[1]);
        setPregunta3(data.GPTpreguntas[2]);
        setPregunta4(data.GPTpreguntas[3]);
      } else {
        GeneratePreguntas();
      }
      
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  const dataItems = [
    { label: 'CARGO',                   value: FetchedData.Charge_name 	? FetchedData.Charge_name.toUpperCase() : '' }, 
    { label: 'EMPRESA',                 value: FetchedData.E_name 			? FetchedData.E_name.toUpperCase() : '' }, 
    { label: 'INTERLOCUTOR',            value: FetchedData.I_name 			? FetchedData.I_name.toUpperCase() : '' },
		{ label: 'PARTNER ENCARGADO',       value: FetchedData.R_name 		  ? FetchedData.R_name.toUpperCase() : '' }
  ];

  //############################################################################################################
  //############################################################################################################

  return (
    <div className='BackGround'>

      <div className='typing-effect-container'>
				<h2 className='typing-effect' > 
          {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
          😍 También he creado para ti este AVISO DE EMPLEO. Puedes editarlo o pedirme que lo genere nuevamente
        </h2>
			</div>
      
      <div className='MainBody'>

        {/* Aviso Generado */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        <div className='boxfit'>
          <h3 className = 'boxtitle'>Aviso Generado</h3>
          { Wait === true ? (
              <div>
                <div className='notas' style={{display:'flex', alignItems:'center', height:'68vh'}}>
                  <img
                    src={'/favicon.png'}
                    alt='Profile'
                    style={{
                      width: '150px',
                      height: '150px',
                      objectFit: 'cover',
                      position:'stick'
                    }}/>
                  <h2 style={{color:'var(--main)'}}>Espera un momento mientras Renee trabaja</h2>
                  <div className='loader-container'>
                    <div  className='pulsing-dot'>
                    <div></div>
                    <div></div>
                    <div></div>
                    </div>
                  </div>
                </div>
              </div>

            ) : (
              <textarea className='notas'
              value={Oferta}
              onChange={handleChange}
              spellCheck />
            )
          }
        </div>
        &nbsp;

        {/* Preguntas Generadas */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        <div className='boxfit'>
          <h3 className = 'boxtitle'>Preguntas de Aviso</h3>
          { Wait2 === true ? (
              <div>
                <div className='notas' style={{display:'flex', alignItems:'center', height:'68vh'}}>
                  <img
                    src={'/favicon.png'}
                    alt='Profile'
                    style={{
                      width: '150px',
                      height: '150px',
                      objectFit: 'cover',
                      position:'stick'
                    }}/>
                  <h2 style={{color:'var(--main)'}}>Espera un momento mientras Renee trabaja</h2>
                  <div className='loader-container'>
                    <div  className='pulsing-dot'>
                    <div></div>
                    <div></div>
                    <div></div>
                    </div>
                  </div>
                </div>
              </div>

            ) : (
              <div className='notas'>
              <textarea className={Final1 ? 'pSaved' : 'preguntas'}
                value={Pregunta1.replace(/"/g, '')}
                placeholder='espere mientras se genera el contenido...'
                onChange={(event) => { let newValue = event.target.value ; setPregunta1(newValue) }}/>
              <button style={{marginBottom:'0.5vh'}} onClick={() => setFinal1(Pregunta1)}> Seleccionar Pregunta </button>
  
              <textarea className={Final2 ? 'pSaved' : 'preguntas'}
                value={Pregunta2.replace(/"/g, '')}
                placeholder='espere mientras se genera el contenido...'
                onChange={(event) => { let newValue = event.target.value ; setPregunta2(newValue) }}/>
              <button style={{marginBottom:'0.5vh'}} onClick={() => setFinal2(Pregunta2)}> Seleccionar Pregunta </button>
  
              <textarea className={Final3 ? 'pSaved' : 'preguntas'}
                value={Pregunta3.replace(/"/g, '')}
                placeholder='espere mientras se genera el contenido...'
                onChange={(event) => { let newValue = event.target.value ; setPregunta3(newValue) }}/>
              <button style={{marginBottom:'0.5vh'}} onClick={() => setFinal3(Pregunta3)}> Seleccionar Pregunta </button>
  
              <textarea className={Final4 ? 'pSaved' : 'preguntas'}
                value={Pregunta4.replace(/"/g, '')}
                placeholder='espere mientras se genera el contenido...'
                onChange={(event) => { let newValue = event.target.value ; setPregunta4(newValue) }}/>
            <button onClick={() => setFinal4(Pregunta4)}> Seleccionar Pregunta </button>
            </div>
            )
          }
        </div>
        &nbsp; 

        {/* Menu Lateral */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        <div className='data-boxes'>
          {dataItems.map((item, index) => (
            <div key={index} className='data-box'>
              <strong>{item.label}:</strong> {item.value}
            </div>
          ))}
          <div style={{display:'grid', gridTemplateColumns:'1fr 1fr', columnGap:'0.5vh', rowGap:'0.5vh'}}>

            <button style={{height:'6vh'}} onClick={GeneratePreguntas}>Regenerar Preguntas Sugeridas</button>
            <button style={{height:'6vh'}} onClick={() => {setRegen(!Regen); setPublicar(false)}}> Regenerar Aviso Laboral </button>
            
            <button className = {Regen ? 'PublicButon' : 'hidden'}
                    onClick={() => GenerateOferta('')}> Regenerar nuevo Aviso Laboral</button>
            <button className = {Regen ? 'PublicButon' : 'hidden'}
                    onClick={() => GenerateOferta(Notas1)}>Regenerar como Consultora</button>
            <button className = {Regen ? 'PublicButon' : 'hidden'}
                    onClick={() => GenerateOferta(Notas2)}>Regenerar como Empresa Contratante</button>
            <button className = {Regen ? 'PublicButon' : 'hidden'}
                    onClick={() => GenerateOferta(Notas3)}>Regenerar como Empresa Confidencial</button>

            <button className='Save' 
                    style={{height:'6vh'}}
                    onClick={SaveData}> Guardar Aviso y preguntas </button>
            <button style={{height:'6vh'}}
                    onClick={FetchData}>Volver a la ultima version guardada</button>

            <button className= {IDlist.length > 3 ? 'hidden' : 'Save'}
                    style={{height:'6vh'}}
                    onClick={() => {setPublicar(!Publicar); setActual(false); setRegen(false)}}>Publicar el Anuncio</button>

            <button className= {IDlist.length > 0 ? 'Save' : 'hidden'}
                    style={{height:'6vh'}}
                    onClick={() => {setActual(!Actual); setPublicar(false); setRegen(false)}}>Actualizar el Anuncio</button>

            {/* <button onClick={SaveJobId}>Limpiar ID</button> */}
              <div style={{display:'flex', flexDirection:'column', gap:'0.5vh'}}>
                <button className = {Publicar ? 'PublicButon' : 'hidden'}
                        onClick={ExportToTrabajando}>Publicar en Trabajando.com</button>
                <button className = {Publicar ? 'PublicButoff' : 'hidden'}
                        >Publicar en LinkedIn</button>
                <button className = {Publicar ? 'PublicButoff' : 'hidden'}
                        >Publicar en Laborum</button>
                <button className = {Publicar ? 'PublicButoff' : 'hidden'}
                        >Publicar en Chile Trabajo</button>
              </div>

              <div style={{display:'flex', flexDirection:'column', gap:'0.5vh'}}>
                <button className = {Actual ? 'PublicButon' : 'hidden'}
                        onClick={UpdateTrabajando}>Actualizar en Trabajando.com</button>
                <button className = {Actual ? 'PublicButoff' : 'hidden'}
                        >Actualizar en LinkedIn</button>
                <button className = {Actual ? 'PublicButoff' : 'hidden'}
                        >Actualizar en Laborum</button>
                <button className = {Actual ? 'PublicButoff' : 'hidden'}
                        >Actualizar en Chile Trabajo</button>
              </div>
                      
          </div>
        </div>
      </div>
      <div className='bottom'>
        <button onClick={goto05}>volver</button>
        <button className={IDlist.length > 0 ? 'Save last-button' : 'hidden'} onClick={() => {SaveData(); goto07()}}>Guardar y Continuar</button>
      </div>
    </div>
  );
}
