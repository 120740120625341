
//=============================================================================
// Pantalla Generacion de Perfil
//=============================================================================

import './css/App.css';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import APICALL from './APICALL';
import AICALL from './AICALL';
import jsPDF from 'jspdf';

export default function Perfil() {

  const navigate = useNavigate();
  const [resp, setResp]               = useState('');
  const [fetchedData, setFetchedData] = useState(''); 
  const [Wait, setWait]               = useState(false);
  const handleChange                  = (event) => { setResp(event.target.value); };

  async function savenotes(){
    setWait(true);
    try {
      await APICALL({
        apicall: 'PD_update',
        code: sessionStorage.getItem('process'), 
        U_emp: sessionStorage.getItem('Grupo'),
        notas: localStorage.getItem('notas') , 
        GPTreply: resp 
      });
    } catch (error) {
      console.error('Error during fetch:', error);
      return { token: null };
    }
    setTimeout(() => {
      setWait(false);
    }, 1000);
  };

  async function handleGenerateText( ) {
    
    setWait(true);
    // console.log(text);
    try {
      const response = await AICALL({
        apicall: '01',
        data:   localStorage.getItem('fetched') 
              + localStorage.getItem('notas')  
              + localStorage.getItem('archivo')
      });
      setResp(response.reply);
    } catch (error) {
      console.error('Error during fetch:', error);
      return { token: null };
    }
    setTimeout(() => {
      setWait(false);
    }, 1000);;
  };

  async function fetchData () {
    const data = await APICALL({
      apicall :'PD_fetch' ,
      code :sessionStorage.getItem('process'),
      U_emp: sessionStorage.getItem('Grupo')
    });
    localStorage.removeItem('fetched')
    localStorage['fetched']= data;
    setFetchedData(data);
    setResp(data.GPTreply)
    if (!data.GPTreply){
      handleGenerateText();
    }
  };

  const exportToPDFperfil = () => {
    setWait(true);
    const doc = new jsPDF({
        format: 'a4',
        compress: true
    });

    const custom = 'Hola! \n Hemos generado este perfil de búsqueda que contiene toda la información que nos proporcionaste para llevar adelante este proceso de head hunting / búsqueda y selección de personas. Si tienes alguna observación por favor coméntanos \n'
    const text = custom + resp
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const lines = text.split('\n');
    const currentDate = new Date();
    const monthNames = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio','julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'];
    const formattedDate = `${currentDate.getDate()} / ${monthNames[currentDate.getMonth()]} / ${currentDate.getFullYear()}`;

    const fontsize = 12
    const titleFontSize = 18
    const margin = 10; // en mm
    const maxLineWidth = pageWidth - 2 * margin;
    const headerHeight = 30; 
    const contentMarginTop = headerHeight + margin;

    const logoRenee   = new Image();
    const logoEficaz  = new Image();
    logoRenee.src   = '/favicon.png'; 
    logoEficaz.src  = '/Eficaz.png'; 
    const logoWidth = 25; 
    const logoHeight = 25;

    const addHeader = () => {
      const headerTextTop = 'Perfil de Búsqueda: \n' 
                          + fetchedData.Charge_name;
      const headerTextBot = 'Documento de Uso Interno - No sale a mercado\n' 
                          + formattedDate;

      doc.setFontSize(fontsize); 
      doc.addImage(logoRenee, 'PNG', margin / 3, margin / 3, logoWidth, logoHeight);
      doc.setFont('helvetica', 'bold'); 
      doc.text(headerTextTop, pageWidth / 2, headerHeight / 3, { align: 'center' }); 
      doc.setFont('helvetica', 'normal'); 
      doc.text(headerTextBot, pageWidth / 2, headerHeight / 3 + 15, { align: 'center' }); 
      doc.addImage(logoEficaz, 'PNG', pageWidth - margin / 3 - logoWidth, margin / 3, logoWidth, logoHeight);
    };

    addHeader();

    doc.setFontSize(fontsize);
    
    let x = margin;
    let y = contentMarginTop;

    lines.forEach(line => {
      let isTitle = false;
      let processedLine = line.trim();
      
      if (line.trim().startsWith('**') && line.trim().endsWith('**')) {
        isTitle = true;
        processedLine = line.trim().substring(2, line.trim().length - 2).trim();
      }      
      
      if (isTitle) {
        doc.setFontSize(titleFontSize);
        doc.setFont('helvetica', 'bold');
        y += titleFontSize * 0.35;
      } else {
        doc.setFontSize(fontsize);
        doc.setFont('helvetica', 'normal');
      }
      
      const textLines = doc.splitTextToSize(processedLine, maxLineWidth);
      const lineHeight = (isTitle ? titleFontSize : fontsize) * 0.35;
      
      textLines.forEach(subLine => {
        if (y + lineHeight > pageHeight - margin) {
          doc.addPage();
          addHeader()
          y = contentMarginTop;
        }
        doc.text(x, y, subLine);
        y += lineHeight;
      });
        
    });
    doc.save('Perfil_'+fetchedData.Charge_name+'.pdf');
    setTimeout(() => {
      setWait(false);
    }, 1000);
  };

  const goto04 = () => { navigate('/P_Editar') };
  async function goto06() {
    try {
      await APICALL({
        apicall: 'PD_update',
        code: sessionStorage.getItem('process'), 
        GPTreply: resp , 
        U_emp: sessionStorage.getItem('Grupo')
      });
    } catch (error) {
      console.error('Error during fetch:', error);
      return { token: null };
    }
    localStorage['reply'] = resp;
    navigate('/P_Oferta');
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await APICALL({
        apicall :'PD_fetch' ,
        code :sessionStorage.getItem('process'),
        U_emp: sessionStorage.getItem('Grupo')
      });
      localStorage.removeItem('fetched')
      localStorage['fetched']= data;
      setFetchedData(data);
      setResp(data.GPTreply)
      if (!data.GPTreply){
        handleGenerateText();
        // console.log('no respuesta')
      }
    };
    fetchData();
  }, []);

  const dataItems = [
    { label: 'CARGO',                   value: fetchedData.Charge_name 	? fetchedData.Charge_name.toUpperCase() : '' }, 
    { label: 'EMPRESA',                 value: fetchedData.E_name 			? fetchedData.E_name.toUpperCase() : '' }, 
    { label: 'INTERLOCUTOR',            value: fetchedData.I_name 			? fetchedData.I_name.toUpperCase() : '' },
		{ label: 'PARTNER ENCARGADO',       value: fetchedData.R_name 		  ? fetchedData.R_name.toUpperCase() : '' }
  ];

  //############################################################################################################
  //############################################################################################################

  return (
    <div className='BackGround'>

      <div className='typing-effect-container'>
				<h2 className='typing-effect' >
          {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
          😎 En base a tus notas he creado para ti este PERFIL DE BÚSQUEDA, puedes editarlo o pedirme que lo genere nuevamente
        </h2>
			</div>

      <div className='MainBody'>
        
      {/* Perfil Generado */}
      {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
      {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
      <div className='boxfit'>
      <h3 className = 'boxtitle'>Perfil Generado</h3>
        { Wait === true ? (
              <div>
                <div className='notas' style={{display:'flex', alignItems:'center', height:'68vh'}}>
                  <img
                    src={'/favicon.png'}
                    alt='Profile'
                    style={{
                      width: '150px',
                      height: '150px',
                      objectFit: 'cover',
                      position:'stick'
                    }}/>
                  <h2 style={{color:'var(--main)'}}>Espera un momento mientras Renee trabaja</h2>
                  <div className='loader-container'>
                    <div  className='pulsing-dot'>
                    <div></div>
                    <div></div>
                    <div></div>
                    </div>
                  </div>
                </div>
              </div>

            ) : (
              <textarea className='notas'
              value={resp}
              onChange={handleChange}
              spellCheck 
            />
            ) }
        </div>
        &nbsp;

        {/* Menu Lateral */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        <div className='data-boxes'>
          {dataItems.map((item, index) => (
            <div key={index} className='data-box'>
              <strong>{item.label}:</strong> {item.value}
            </div>
          ))}
          <div style={{display:'grid', gridTemplateColumns:'1fr 1fr', columnGap:'0.5vh', rowGap:'0.5vh' }}>

            <button style={{height:'6vh'}} onClick={fetchData}>Volver a la ultima version guardada</button>
            <button style={{height:'6vh'}} onClick={handleGenerateText}> Regenerar el Perfil </button>

            <button className='Save' style={{height:'6vh'}} onClick={savenotes}> Guardar Perfil de Busqueda </button>
            <button className='Save' style={{height:'6vh'}} onClick={exportToPDFperfil}>Exportar Perfil de Busqueda</button>
          </div>
        </div>
      </div>
        <div className='bottom'>
          <button onClick={goto04}>Volver</button>
          <button className='Save last-button' onClick={() => {savenotes() ; goto06()}}>Guardar y Continuar</button>
        </div>
    </div>
  );
}
