
//=============================================================================
// Función Genérica Renee_AI
//=============================================================================

export default async function AICALL(data) {

  const ID = sessionStorage.getItem('environmentAI');
  let URL 

  const options = {
    method: 'POST',
  };

  if (data instanceof FormData) {
    options.body = data;
  } else {
    options.headers = { 'Content-Type': 'application/json' };
    options.body = JSON.stringify(data);
    options.mode = 'cors'
  }

  if (ID === 'Local') {
		URL = 'http://localhost:8060/AIcall'
	} else {
		URL = 'https://api.renee.cl/Aicall'
	}

  try {
    const response = await fetch(URL, options);

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    return await response.json();
  } catch (error) {
    console.error('Error during fetch:', error);
    return { response: '' };
  }
}
