import React, { Component } from 'react';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error('Error caught by ErrorBoundary:', error, errorInfo);
    this.setState({
      error: error.toString(), 
      errorInfo: errorInfo.componentStack || errorInfo, // Usa el stack si está disponible
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div style={{ marginLeft: '2vh', fontSize:'1.3rem' }}>
          <h1>Oops, parece que encontramos un error.</h1>
          <h2>Error: {this.state.error}</h2>
          <div
            style={{
              marginTop: '1vh',
              marginRight:'3vh',
              whiteSpace: 'pre-wrap',
              flex: '1',
              overflowY: 'scroll',
              textAlign: 'left',
              fontSize:'1rem',
              color: 'var(--dark)',
              border: '3px solid var(--dark)',
              padding: '10px',
              backgroundColor: 'var(--light)',
            }}
          >
            <h3>Detalles del error:</h3>
            <pre>{this.state.errorInfo}</pre>
          </div>
        </div>
      );
    }

    return this.props.children; 
  }
}

export default ErrorBoundary;
