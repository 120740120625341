
//=============================================================================
// Pantalla Listado de Procesos
//=============================================================================

import './css/App.css';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import APICALL from './APICALL';

export default function Pprocess() {

  const navigate = useNavigate();
  const [FetchedData, setFetchedData] = useState(''); 
  const [FilterQuery, setFilterQuery] = useState(''); 
  const [SelectedReply, setSelectedReply] = useState('');
  const [Replies, setReplies] = useState([]);
  const [FilteredReplies, setFilteredReplies] = useState([]); 
  const [Arch, setArch] = useState(false);
  const [Check, setCheck] = useState(false);
  const [Warning, setWarning] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [ShowArchived, setShowArchived] = useState(false);

  let [Grupos] = useState([]);

  async function Archive (value) {
    setLoading(true);
    await APICALL({
      apicall: 'PD_arch',
      code: sessionStorage.getItem('process'),
      R_name: FetchedData.R_name, 
      U_emp: sessionStorage.getItem('Grupo'),
      archived: value
    });
    List(!value);
    setCheck(false);
    setLoading(false);
    setArch(false);
  };

  async function DropProcess () {
    setLoading(true);
    await APICALL({
      apicall: 'PD_drop',
      code: sessionStorage.getItem('process'),
      R_name: FetchedData.R_name, 
      U_emp: sessionStorage.getItem('Grupo')
    });
    List();
    setCheck(false);
    setLoading(false);
    setWarning(false);
  };
  
  async function FetchData() {
    const data = await APICALL({
      apicall: 'PD_fetch',
      code: sessionStorage.getItem('process'),
      U_emp: sessionStorage.getItem('Grupo')
    });
    sessionStorage.setItem('perfil', data.GPTreply);
    setFetchedData(data);
  };

  async function correccion() {
    setLoading(true);
    await APICALL({
      apicall: 'FX_00',
      code: sessionStorage.getItem('process'),
      U_emp: sessionStorage.getItem('Grupo')
    });
    await setFetchedData(FetchData());
    setLoading(false)
  };

  async function Handlecheck (value, reply) {
    setSelectedReply(value);
    let transformedback = value.replace(/ \| /g, '_');
    sessionStorage.removeItem('process');
    sessionStorage.removeItem('name');
    sessionStorage.setItem('process', transformedback);
    sessionStorage.setItem('name', reply);
    await setFetchedData(FetchData());
    setCheck(true);
  };

  async function List(state) {
    setLoading(true);
    const process = await APICALL({
      apicall: 'PD_list',
      R_name: sessionStorage.getItem('token'),
      U_emp: sessionStorage.getItem('Grupo')
    });
  
    if (process && Array.isArray(process)) {
      let filteredProcesses = process.filter(item => 
        item[1].toUpperCase() === sessionStorage.getItem('Grupo').toUpperCase() &&
        (state ? item.includes('archived: true') : !item.includes('archived: true'))
      );
  
      for (let i = 0; i < process.length; i++) {
        if (Grupos.indexOf(process[i][1]) === -1) {
          Grupos.push(process[i][1]);
        }
      }
      sessionStorage.setItem('gruposlista', JSON.stringify(Grupos));
  
      const transformedReplies = filteredProcesses.map(item => ({
        reply: item[2].replace(/_/g, ' | '),
        value: item[0]
      })).reverse();
      
      setReplies(transformedReplies);
      setFilteredReplies(transformedReplies);
      setLoading(false);
    } else {
      setReplies([]); 
      setLoading(false);
    }
  };

  const HandleFilterChange = (e) => {
    const query = e.target.value.toLowerCase();
    setFilterQuery(query);
    
    const filtered = Replies.filter(reply => 
      reply.reply.toLowerCase().includes(query)
    );
  
    setFilteredReplies(filtered);
  };

  const ToggleArchived = () => {
    setShowArchived(!ShowArchived);
    List(!ShowArchived); 
  };

  const Goto02 = () => { navigate('/CrearProceso') };
  const Goto05 = () => { navigate('/P_Notas') };
  const Goto07 = () => { navigate('/P_Candidatos') };

  useEffect(() => {
    sessionStorage.removeItem('process');
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2 * 1000);
    List();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const DataItems = [
    { label: 'EMPRESA', value: FetchedData.E_name ? FetchedData.E_name.toUpperCase() : ' - - - ' }, 
    { label: 'CARGO', value: FetchedData.Charge_name ? FetchedData.Charge_name.toUpperCase() : ' - - - ' }, 
    { label: 'HEAD HUNTER', value: FetchedData.R_name ? FetchedData.R_name.toUpperCase() : ' - - - ' }, 
    { label: 'INTERLOCUTOR', value: FetchedData.I_name ? FetchedData.I_name.toUpperCase() : ' - - - ' },
    { label: 'ID', value: Array.isArray(FetchedData.JobId) ? FetchedData.JobId.join(' | ') : (FetchedData.JobId || ' - - - ') },
    { label: 'FECHA LIMITE', value: FetchedData.Timewith ? FetchedData.Timewith.toUpperCase() + ' DIAS' : ' - - - ' }
  ];

  //############################################################################################################
  //############################################################################################################  

  return (

    <div className='BackGround'>	

      {/* Pop-up Eliminar Proceso */}
      <div onClick={() => setWarning(false)} className={Warning ? 'WarningBG' : 'hidden'}>
        <div className={Warning ? 'warningbox' : 'hidden'}>
          <h1>¿Está Seguro que desea Eliminar el Proceso?</h1>
          <div className='data-summary'>
            {DataItems.map((item, index) => (
              <div key={index} className='data-item'>
                <strong>{item.label}:</strong> {item.value}
              </div>
            ))}
          </div>
          Esta acción es PERMANENTE y NO SE PUEDE DESHACER. 
          Al eliminar este proceso, se perderán todos los datos e información asociada, 
          y no habrá forma de recuperarlos.
          Asegúrese de haber realizado copias de cualquier documento o informacion relevante 
          si esta es necesaria, ya que el proceso es IRREVERSIBLE
          <div style={{display:'flex', padding:'1vh', gap:'1vh', width:'-webkit-fill-available'}}>
            <button className='redb' onClick={DropProcess}>si, quiero Eliminar este proceso</button>
            <button onClick={() => setWarning(false)}>Cancelar</button>
          </div>
        </div>
      </div>

      {/* Pop-up Archivar Proceso */}
      <div onClick={() => setArch(false)} className={Arch && !ShowArchived ? 'WarningBG' : 'hidden'}>
        <div className={Arch ? 'warningbox' : 'hidden'}>
          <h1>¿Está Seguro que desea Archivar este Proceso?</h1>
          <div className='data-summary'>
            {DataItems.map((item, index) => (
              <div key={index} className='data-item'>
                <strong>{item.label}:</strong> {item.value}
              </div>
            ))}
          </div>
          De proceder, este proceso se enviara a la lista de procesos archivados
          <div style={{display:'flex', padding:'1vh', gap:'1vh', width:'-webkit-fill-available'}}>
            <button className='redb' onClick={() => Archive(true)}>si, quiero ARCHIVAR este proceso</button>
            <button onClick={() => setWarning(false)}>Cancelar</button>
          </div>
        </div>
      </div>

      {/* Pop-up Desarchivar Proceso */}
      <div onClick={() => setArch(false)} className={Arch && ShowArchived ? 'WarningBG' : 'hidden'}>
        <div className={Arch ? 'warningbox' : 'hidden'}>
          <h1>¿Está Seguro que desea Desarchivar este Proceso?</h1>
          <div className='data-summary'>
            {DataItems.map((item, index) => (
              <div key={index} className='data-item'>
                <strong>{item.label}:</strong> {item.value}
              </div>
            ))}
          </div>
          De proceder, este proceso se enviara a la lista de procesos activos
          <div style={{display:'flex', padding:'1vh', gap:'1vh', width:'-webkit-fill-available'}}>
            <button onClick={() => Archive(false)}>si, quiero DESARCHIVAR este proceso</button>
            <button onClick={() => setWarning(false)}>Cancelar</button>
          </div>
        </div>
      </div>

      <div className='typing-effect-container'>
        <h2 className='typing-effect'>
          {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
          Hola, soy Reneé 👋... Indícame si quieres que te ayude en un proceso existente o si quieres crear uno nuevo 
        </h2>
      </div>		

      <div className='MainBody'>

        {/* Lista de procesos */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        <div className='ColumnCenter' style={{marginRight:'1vh'}}>
        <h3 className='form-title'>{ShowArchived ? 'Tus Procesos Archivados' : 'Tus Procesos Activos'}</h3>
          <div style={{width:'100%'}}>
           <div className='box3'>
             <input 
               type='text' 
               placeholder='Búsqueda de proceso' 
               value={FilterQuery}
            style={{marginBottom:'0vh', border:'0.3vh solid var(--main)', borderRadius:'1vh', paddingLeft:'1vh'}}
               onChange={HandleFilterChange} 
             />
             <button onClick={()=>List(ShowArchived)}>
               Actualizar la lista 
             </button>
             <button onClick={ToggleArchived}>
               {!ShowArchived ? 'Mostrar procesos Archivados' : 'Mostrar procesos Activos'}
             </button>
           </div>
    
           <div>
             { Loading === true ? (
               <div>
                 <div className='box'>
                   <img
                     src={'/favicon.png'}
                     alt='Profile'
                     style={{
                       width: '150px',
                       height: '150px',
                       objectFit: 'cover',
                       position:'stick'
                     }}/>
                   <h2 style={{color:'var(--main)'}}>Espera un momento mientras Renee trabaja</h2>
                   <div className='loader-container'>
                     <div  className='pulsing-dot'>
                     <div></div>
                     <div></div>
                     <div></div>
                     </div>
                   </div>
                 </div>
               </div>
    
             ) : (
               <div className='mainselect'>
                  <button style={{position:'sticky', top: 0, zIndex: 4, marginBottom:'0.5vh'}} 
                          onClick={Goto02}>
                    Crear nuevo proceso
                  </button>
                 {FilteredReplies.length > 0 ? 
                   FilteredReplies
                   .map((item, index) => (
                     <div key={index} style={{ display: 'flex', alignItems: 'baseline' }}>
                       <button 
                         onClick={() => Handlecheck(item.value, item.reply)} 
                         style={{  marginBottom: '0.5vh', 
                                   marginRight:'0.5vh', 
                                   padding: '1vh' }}
                         className={!ShowArchived ? (item.value === SelectedReply ? 'highlighted' : 'Item' ): 'PublicButoff'}
                       >
                         {item.reply.toUpperCase()}
                       </button>
                       <button 
                         onClick={() => {Handlecheck(item.value, item.reply); setArch(true)}} 
                         className='box-button redb'
                       >
                         <i className='fas fa-archive'></i>
                       </button>
                     </div>
                   )) : 
                   <button className='PublicButoff'>
                     Sin procesos Activos
                   </button>
                 }
               </div>
             )}
           </div>
          </div>
        </div>

        {/* Detalles de Proceso */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */}
        <div className='ColumnCenter' style={{marginLeft:'1vh'}}>
        <h3 className={Check ? 'form-title' : 'hidden'}> Datos de tu proceso</h3>
          {Check ? (
            <div style={{width:'100%'}}>
              { Loading === true ? (
                <div>
                  
                </div>
              ) : (
                <div>
                  <div className='box3'>
                    <button onClick={Goto05}>Continuar Proceso</button>
                    <button className={FetchedData.JobId? '' : 'hidden'} onClick={Goto07}>Continuar a Candidatos</button>
                    <button className='redb' onClick={() => setWarning(true)}>Eliminar Proceso</button>
                  </div>
                  <div className='mainselect' style={{overflow:'hidden'}}>
                    <div className={Check ? 'data-boxes2' : ' hidden' }>
                      {DataItems.map((item, index) => (
                        <div key={index} className='data-box'>
                          <strong>{item.label}:</strong> {item.value}
                        </div>
                      ))}
                      <button className={sessionStorage.getItem('mail') === 'admin'? '' : 'hidden'} onClick={correccion}>Corregir datos del proceso</button>
                    </div>
                    <div className={Loading ? 'loading' : 'hidden'}>
                    </div>
                  </div> 
                </div>
              )}
            </div>
          ) : (
            <div className='notas' style={{marginBottom:'-2vh', fontSize:'1.2rem', alignItems:'center', border:'none'}}>
              <h3 className='form-title'>¡Bienvenidos a la versión 0.34.1 de ReneeApp!</h3>
                <p> tu asistente virtual para ayudarte en el desarrollo de tus procesos de busquedas de candidatos</p>
                <p>Estos son los cambios que podrás observar en esta versión:</p>
                <div style={{overflow:'scroll'}} className='popup-content'>
                  <div style={{textAlign:'start'}}>
                  <ul>
                    <li>Se agregó una función de reparación de procesos.</li>
                    <li>Se ajustaron algunos elementos visuales de la página.</li>
                    <li>Se invirtió el orden de los procesos en inicio.</li>
                    <li>Se ajustaron los elementos visuales para usuarios con zoom hasta 150%.</li>
                    <li>Se corrigió un error en el proceso de registro de paso a tercera persona en formularios.</li>
                    <li>Se corrigió un error crítico en el proceso de guardado de formularios en tercera persona.</li>
                  </ul>
                  </div>
                </div>
            </div>
          )}
          </div>
      </div>
    </div>
  );
}
