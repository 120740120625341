import './header.css';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Chatbox from './Chatbox';

function logout() {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('chatMessages');
    window.location.replace('https://renee.cl');
};

const namestring = sessionStorage.getItem('token');

const HeaderAdmin = () => {
    const [showChatbox, setShowChatbox] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [rolmenushow, setRolmenue] = useState(false);
    const Rollist = JSON.parse(sessionStorage.getItem('gruposlista'));
    const [EnvMain, setEnvMain] = useState(sessionStorage.getItem('environment') || 'Production');
    const [EnvAI, setEnvAI] = useState(sessionStorage.getItem('environment') || 'Production');
    const [Tst, setTst] = useState(sessionStorage.getItem('test') || 'Production');

    const navigate = useNavigate();
    let location = useLocation();
    location = location.pathname;

    const goto01 = () => { 
        setIsOpen(false);
        setRolmenue(false);
        navigate('/');
    };

    const gotoNU = () => {  
        setIsOpen(!isOpen);
        setRolmenue(false);
        navigate('/NuevoUser');
    };

    const gotoPF = () => {  
        setIsOpen(!isOpen);
        setRolmenue(false);
        navigate('/MiPerfil');
    };

    const gotoIdeas = () => {  
        setIsOpen(!isOpen);
        setRolmenue(false);
        navigate('/Ideas');
    };

    const rolmenu = () => {
        setRolmenue(!rolmenushow)
    };
    
    const rolchange = (option) => {
        sessionStorage.setItem('Grupo', option);
        setIsOpen(!isOpen);
        setRolmenue(false)
    };

    const changeenvMain = () => {
        if (sessionStorage.getItem('environmentMain') === 'Local'){
            sessionStorage.setItem('environmentMain', 'Production');
            setEnvMain('Production')
        } else {
            sessionStorage.setItem('environmentMain', 'Local');
            setEnvMain('Local')
        }
    };

    const changeenvAI = () => {
        if (sessionStorage.getItem('environmentAI') === 'Local'){
            sessionStorage.setItem('environmentAI', 'Production');
            setEnvAI('Production')
        } else {
            sessionStorage.setItem('environmentAI', 'Local');
            setEnvAI('Local')
        }
    };

    const changetest = () => {
        if (sessionStorage.getItem('test') === 'Test'){
            sessionStorage.setItem('test', 'Production');
            setTst('Production');
        } else {
            sessionStorage.setItem('test', 'Test');
            setTst('Test');
        }
    };

    const toggleChatbox = () => {
        setIsOpen(false);
        setRolmenue(false);
        setShowChatbox(!showChatbox);
    };

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
        setRolmenue(false);
    };

    const handleOptionClick = (option) => {
        if (option.action) {
            option.action(); 
        }
    };

    const options = [
        { label: '🏠 Inicio', action: goto01 },
        { label: '💬 Consulta a Reneé', action: toggleChatbox },
        { label: '🤵 Crear Usuario', action: gotoNU },
        { label: '🔒 Cambia tu contraseña', action: gotoPF },
        { label: '🏢 Cambiar de Empresa', action: rolmenu },
        { label: '📧 Reportes o Sugerencias', action: gotoIdeas },
        { label: '⛔ Salir de Reneé', action: logout },
    ];
    
    if (sessionStorage.mail === 'admin') {
        options.push(
            { label: ' '},
            { label: '💻 ENVIROMENT : ' + Tst, action: changetest },
            { label: '💻 Pointer Main : ' + EnvMain, action: changeenvMain },
            { label: '💻 Pointer AI : '   + EnvAI, action: changeenvAI }
 
        );
    }

    return (
        
        <div>
            <header className='header'>
                <img 
                      src='/favicon3.png' 
                      alt='favicon' 
                      className='logo' 
                      onClick={goto01}
                  />
                <div className='titles'>
                { location.includes('P_') ? (
                    <h3 className='title'>{sessionStorage.getItem('name').toUpperCase() || 'Error'}</h3>          
                ) : (
                    <h3 className='title'>Bienvenido a Reneé || {namestring.toUpperCase()} {sessionStorage.getItem('Grupo').toUpperCase()}</h3>
                )}
                  <button 
                    className='chat-button' 
                    onClick={toggleChatbox} 
                    style={{height:'4vh', width:'4vh'}}> 
                    {/* eslint-disable-next-line jsx-a11y/accessible-emoji */} 
                    💬 
                  </button>
                  <button 
                    className='chat-button' 
                    onClick={toggleDropdown}
                    style={{fontSize:'1.5rem'}}>
                    ☰
                  </button>
 

     
                </div>
                <div className='subtitle'>
                    <button className={location === '/' ? 'sub-box-on' : 'sub-box-off'}>
                        <h3>Tus Procesos</h3>
                    </button>
                    <button className={location === '/CrearProceso' ? 'sub-box-on' : 'sub-box-off'}>
                        <h3>Crear Proceso</h3>
                    </button>
                    <button className={location === '/P_Notas' ? 'sub-box-on' : 'sub-box-off'}>
                        <h3>Levantamiento de Perfil</h3>
                    </button>
                    <button className={location === '/P_Editar' ? 'sub-box-on' : 'sub-box-off'}>
                        <h3>Datos del Perfil</h3>
                    </button>
                    <button className={location === '/P_Perfil' ? 'sub-box-on' : 'sub-box-off'}>
                        <h3>Perfil de Búsqueda</h3>
                    </button>
                    <button className={location === '/P_Oferta' ? 'sub-box-on' : 'sub-box-off'}>
                        <h3>Aviso de Empleo</h3>
                    </button>
                    <button className={location === '/P_Candidatos' ? 'sub-box-on' : 'sub-box-off'}>
                        <h3>Revision de candidatos</h3>
                    </button>
                    <button className={location === '/P_Formulario' ? 'sub-box-on' : 'sub-box-off'}>
                        <h3>Formulario Pre-Screening</h3>
                    </button>
                    <button className={location === '/P_Respuestas' ? 'sub-box-on' : 'sub-box-off'}>
                        <h3>Respuestas Pre-Screening</h3>
                    </button>
                </div>
                {showChatbox && <Chatbox closeChatbox={toggleChatbox} />}
            </header>
            <div style={{position:'fixed', zIndex:'10'}}>
            <div className={`custom-dropdown ${isOpen ? 'active' : ''}`}>
                <div className='dropdown-list'>
                    {options.map((option, index) => (
                        <div
                            key={index}
                            className={`dropdown-item ${rolmenushow && option.label === '🏢 Cambiar de Empresa' ? 'show-options' : ''}`}
                            onClick={() => handleOptionClick(option)}>
                            {option.label}
                            {option.label === '🏢 Cambiar de Empresa' && rolmenushow && (
                                <div className={`dropdown2 ${rolmenushow ? 'active' : ''}`}>
                                    {[...new Set(Rollist.map(option => option.toLowerCase()))].map((subOption, index) => (
                                        <div
                                            key={index}
                                            className={`dropdown-item`}
                                            onClick={() => {
                                                rolchange(subOption.charAt(0).toUpperCase() + subOption.slice(1));
                                                setRolmenue(false);
                                            }}
                                        >
                                            {/* // eslint-disable-next-line */}
                                            🏬 {subOption.charAt(0).toUpperCase() + subOption.slice(1)}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
            </div>
        </div>
    );
};

export default HeaderAdmin;
